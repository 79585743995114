import Vue from 'vue'
import App from './App.vue'
import '@/common/css/base.css'
import routers from "@/router";
import VueRouter from 'vue-router'
import {Message} from 'element-ui';

Vue.config.productionTip = false
Vue.use(VueRouter);
Vue.component(Message);
// 新增以下代码，解决NavigationDuplicated问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
    mode: 'history',
    routes: routers
})
new Vue({
    el: '#app',
    router,
    render: h => h(App),
})

