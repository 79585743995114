<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    console.log(this.$route)
  }
}
</script>

<style>
#app {
  width: 100%;
}

@font-face {
  font-family: "思源黑体 CN Regular";
  src: url(https://picture.aismile.cn/web-static/yueyashidai.com/SourceHanSansCN-Regular.ttf);
}

@font-face {
  font-family: "思源黑体 CN Medium";
  src: url(https://picture.aismile.cn/web-static/yueyashidai.com/SourceHanSansCN-Medium.ttf);
}

@font-face {
  font-family: "思源黑体 CN Bold";
  src: url(https://picture.aismile.cn/web-static/yueyashidai.com/SourceHanSansCN-Bold.ttf);
}

@font-face {
  font-family: "思源黑体 CN Light";
  src: url(https://picture.aismile.cn/web-static/yueyashidai.com/SourceHanSansCN-Light.ttf);
}

@font-face {
  font-family: "思源黑体 CN Normal";
  src: url(https://picture.aismile.cn/web-static/yueyashidai.com/CN-Normal.ttf);
}
</style>
